<template>
	<div class="app-view">
		<div class="controls-container">
		
		<div class="select-wrapper">
      <select v-model="cornGroupId" id="select-art" @change="updateLSVArtenFilter">
        <option v-for="art in LSVArten" :key="art.ID" :value="art.ID">{{art.bezeichnung}}</option>
      </select>
		</div>
    <div class="grid">
      <div class="row">
        <div class="col col50">
          <div class="select-wrapper">
              <select v-model="cornGroupValues" id="select-values">
                <option value="0">Mittelwerte Kornertrag Stufe 2 relativ</option>
                <option value="1">Öko-Mittelwerte Kornertrag Stufe 2 relativ</option>
                <option value="2">Einzelwerte Kornertrag Stufe 2 relativ</option>
              </select>
          </div>
        </div>
        <div class="col col50">
          <div class="select-wrapper">
              <select v-model="cornGroupYear" id="select-year">
                <option v-for="jahr in LSVJahre" :key="jahr.Jahr" :value="jahr.Jahr">{{jahr.Jahr}}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
		<div class="select-wrapper">
        <select v-model="cornGroupBland" id="select-bland">
          <option value="">Alle Bundesländer</option>
          <option v-for="bland in LSVBlaender" :key="bland.ID" :value="bland.ID">{{bland.bezeichnung}}</option>
        </select>
		</div>
		<div class="control-buttons-wrapper">
			<div class="control-button">
        <button data-target="searchterm-filter" @click="switchFilter($event)"><span class="button-title">Sorte suchen</span></button>
			</div>
			<div class="control-button">
        <button data-target="sort-filter" @click="switchFilter($event)"><span class="button-title">Sortierung/Filter</span></button>
			</div>
			<!-- <div class="control-button">
        <button data-target="comparison-filter" click="switchFilter($event)"><span class="button-title">Reihenfolge</span></button>
			</div> -->
		</div>
		<div class="filter-container-wrapper">
			<div class="filter-searchterm-wrapper filter-container" data-active="false" id="searchterm-filter">
        <input type="text" v-model="this.searchTerm" placeholder="Suchbegriff.." />
        <button @click="this.searchTerm = ''"></button>
			</div>
        <div class="filter-outer-wrapper filter-container" data-active="false" id="sort-filter" v-if="1">
          <!-- <div class="filter-wrapper-title">Sortierung/Filter</div> -->
          <div class="filter-wrapper">
            <div :class="{'single-filter': true, 'filter-range': filterFeld.Filter == 1}" v-for="(filterFeld, filterKey) in filterFelder" :key="filterKey" v-show="filterFeld.FilterErweitert !== '1'">
              <div class="filter-title">{{filterFeld.Bezeichnung}}:<span class="range-wrapper" v-if="filterFeld.Filter == 1" :class="{active: filterFelder[filterKey].selectedFilter[0] !== filterFelder[filterKey].min || filterFelder[filterKey].selectedFilter[1] !== filterFelder[filterKey].max}"><span class="mini" v-html="filterFelder[filterKey].selectedFilter[0]"></span> - <span class="maxi" v-html="filterFelder[filterKey].selectedFilter[1]"></span></span></div>
              <div class="filter-config" v-if="filterFeld.Filter == 2">
                <div class="select-wrapper">
                  <select :data-filter="filterKey" @change="filterResults()" v-model="filterFelder[filterKey].selectedFilter">
                    <option></option>
                    <option v-for="filterVal in filterFeld.FilterVal" :key="filterVal">{{ filterVal }}</option>
                  </select>
                </div>
              </div>
              <div class="filter-config" v-else-if="filterFeld.Filter == 1">
                
                <Slider :data-filter="filterKey" v-model="filterFelder[filterKey].selectedFilter" v-bind="filterFelder[filterKey]" @change="filterResults()" />

                <!-- <div class='range-slider' :class="{active: filterFelder[filterKey].selectedFilter[0] !== filterFelder[filterKey].FilterVal[0] || filterFelder[filterKey].selectedFilter[1] !== filterFelder[filterKey].FilterVal[1] }">
                  <input type="range" v-model.number="filterFelder[filterKey].selectedFilter[0]" :data-filter="filterKey" :data-init="filterFelder[filterKey].FilterVal[0]" min="0" max="9" @input="filterResults($event)" />
                  <input type="range" v-model.number="filterFelder[filterKey].selectedFilter[1]" :data-filter="filterKey" :data-init="filterFelder[filterKey].FilterVal[1]" min="0" max="9" @input="filterResults($event)" />
                </div> -->
                <!-- Vorher:
                <input type="number" v-model.number="filterFelder[filterKey].selectedFilter[0]" :data-filter="filterFeld.Key" :data-init="filterFeld.FilterVal[0]" :min="filterFeld.FilterVal[0]" :max="parseInt(parseInt(filterFeld.FilterVal[1]) - 1)" @input="filterResults($event)" />
                <input type="number" v-model.number="filterFelder[filterKey].selectedFilter[1]" :data-filter="filterFeld.Key" :data-init="filterFeld.FilterVal[1]" :min="parseInt(parseInt(filterFeld.FilterVal[0]) + 1)" :max="filterFeld.FilterVal[1]" @input="filterResults($event)" /> -->
              </div>

            </div>
            <div class="single-filter full"></div>
            <div class="single-filter">
              <div class="filter-title"></div>
              <div class="filter-config">
                <button type="button" @click="updateLSVArtenFilter()">Filter löschen</button>
              </div>
            </div>
          </div>
        </div>
      <div class="comparison-outer-wrapper filter-container" data-active="false" id="comparison-filter" v-if="0">
        <div class="comparison-wrapper">
          <div class="comparison-description">Zum Vergleichen von Sorten wählen Sie die gewünschten Sorten in der Liste an und klicken anschließend auf den Button.</div>
          <div class="comparison-button-wrapper">
            <button type="button" class="comparison-button" click="cornComparison()">Starten</button>
          </div>
        </div>
			</div>
		</div>
		</div>
    
    <div class="results-outer-wrapper" v-if="false && debugData!=null">
      <div class="results-compared-props">
        <div data-key="sorte" @click="sortSubset($event)"><div>Sorte ({{debugData.length}})</div></div>
        <div data-key="typ" @click="sortSubset($event)"><div>Typ</div></div>
        <div><div>Jahr</div></div>
        <div v-if="0">Artenschlüssel</div>
        <div data-key="bl" @click="sortSubset($event)"><div>BL</div></div>
        <div data-key="mw" @click="sortSubset($event)"><div>Besch.</div></div>
        <div data-key="ort" @click="sortSubset($event)"><div>Ort</div></div>
        <div data-key="mittel" @click="sortSubset($event)"><div>Wert*</div></div>
        <div data-key="rel" @click="sortSubset($event)"><div>&Oslash;</div></div>
        <div>Quelle</div>
      </div>
      <div class="single-result" v-for="singleLSV of debugData" :key="singleLSV.identifier" click="toggleActive($event)">
        <div class="result-compare-property">
          <div class="property-value"><span v-html="singleLSV.sorte.Bez"></span></div>
        </div>
        <div class="result-compare-property">
          <span v-if="singleLSV.typ === 0">LSV</span>
          <span v-else-if="singleLSV.typ === 1">Öko-LSV</span>
          <span v-else>BSV</span>
        </div>
        <div class="result-compare-property" v-html="singleLSV.jahr"></div>
        <div class="result-compare-property" v-if="0"><!-- Artenschlüssel Wert --></div>
        <div class="result-compare-property" v-html="singleLSV.ort.bundesland.bezeichnung"></div>
        <div class="result-compare-property" v-if="singleLSV.ort.name === 'Mittel Alle' || singleLSV.ort.name === 'Mittel alle'">Mittelwert</div>
        <div class="result-compare-property" v-else></div>
        <div class="result-compare-property" v-html="singleLSV.ort.name"></div>
        <div class="result-compare-property" v-html="singleLSV.mittelWert"></div>
        <div class="result-compare-property" v-html="singleLSV.relWert"></div>
        <div class="result-compare-property"><a :href="singleLSV.ort.bundesland.quelle" target="_blank">Link</a></div>
      </div>
    </div>
    <div class="results-outer-wrapper" v-else-if="cornGroupValues !== '2'">
      <div class="results-compared-props">
          <div data-key="sorte" @click="sortSubset($event)"><div>Sorte ({{ LSVsortenSubset.length }})</div></div>
          <div><div>Jahr</div></div>
          <div v-for="bland in LSVBlaender.filter(bl => {if(bl.ID!==17) return bl})" :key="bland.ID" data-key="bundesland" :data-bundesland="bland.ID" @click="sortSubset($event)"><div>{{bland.kurzBezeichnung}}</div></div>
          <div data-key="gesMittel" @click="sortSubset($event)"><div>Ges.</div></div>
          <div data-key="bundesland" data-bundesland="17" @click="sortSubset($event)"><div>BSV</div></div>
      </div>
      <div class="single-result" v-for="singleLSV of paginatedLSVsortenSubset" :key="singleLSV.identifier" click="toggleActive($event)">
        <div class="result-compare-property">
          <div class="property-value"><span v-html="singleLSV.sorte.Bez"></span></div>
        </div>
        <div class="result-compare-property" v-html="singleLSV.jahr"></div>
        <div class="result-compare-property" v-for="bland in LSVBlaender.filter(bl => {if(bl.ID!==17) return bl})" :key="bland.ID">{{ getBlandVal(singleLSV, bland.ID)}}</div>
        <div class="result-compare-property" v-if="singleLSV.anzahl > 0">{{ (singleLSV.summe / singleLSV.anzahl).toFixed(1) }}</div>
        <div class="result-compare-property" v-else>-</div>
        <div class="result-compare-property" v-for="bland in LSVBlaender.filter(bl => {if(bl.ID===17) return bl})" :key="bland.ID">{{ getBlandVal(singleLSV, bland.ID)}}</div>
      </div>
    </div>
    <div class="results-outer-wrapper" v-else>
      <div class="results-compared-props">
        <div data-key="sorte" @click="sortSubset($event)"><div>Sorte ({{origLSVSubset.length}})</div></div>
        <div data-key="typ" @click="sortSubset($event)"><div>Typ</div></div>
        <div><div>Jahr</div></div>
        <div v-if="0">Artenschlüssel</div>
        <div data-key="bl" @click="sortSubset($event)"><div>BL</div></div>
        <div data-key="mw" @click="sortSubset($event)"><div>Besch.</div></div>
        <div data-key="ort" @click="sortSubset($event)"><div>Ort</div></div>
        <div data-key="mittel" @click="sortSubset($event)"><div>Wert*</div></div>
        <div data-key="rel" @click="sortSubset($event)"><div>&Oslash;</div></div>
        <div>Quelle</div>
      </div>
      <div class="single-result" v-for="singleLSV of paginatedLSVorigSubset" :key="singleLSV.identifier" click="toggleActive($event)">
        <div class="result-compare-property">
          <div class="property-value"><span v-html="singleLSV.sorte.Bez"></span></div>
        </div>
        <div class="result-compare-property">
          <span v-if="singleLSV.typ === 0">LSV</span>
          <span v-else-if="singleLSV.typ === 1">Öko-LSV</span>
          <span v-else>BSV</span>
        </div>
        <div class="result-compare-property" v-html="singleLSV.jahr"></div>
        <div class="result-compare-property" v-if="0"><!-- Artenschlüssel Wert --></div>
        <div class="result-compare-property" v-html="singleLSV.ort.bundesland.bezeichnung"></div>
        <div class="result-compare-property" v-if="singleLSV.ort.name === 'Mittel Alle' || singleLSV.ort.name === 'Mittel alle'">Mittelwert</div>
        <div class="result-compare-property" v-else></div>
        <div class="result-compare-property" v-html="singleLSV.ort.name"></div>
        <div class="result-compare-property" v-html="singleLSV.mittelWert"></div>
        <div class="result-compare-property" v-html="singleLSV.relWert"></div>
        <div class="result-compare-property"><a :href="singleLSV.ort.bundesland.quelle" target="_blank">Link</a></div>
      </div>
    </div>
    
    <div class="result-pagination-wrapper">
      <div class="result-pagination" v-if="LSVsortenSubset.length > 0 && cornGroupValues !== '2'">
        <div class="pagination-selector-wrapper" :class="{active: (currentPage == 0)}"><div class="pagination-selector" @click="currentPage = 0">1</div></div>
        <div v-for="(item, i) in Math.floor((LSVsortenSubset.length - 1) / 20)" :key="i" :value="i+1" class="pagination-selector-wrapper" :class="{active: (currentPage == i + 1)}"><div class="pagination-selector" @click="currentPage = i + 1">{{i+2}}</div></div>
      </div>
      <div class="result-pagination" v-else-if="cornGroupValues === '2' && origLSVSubset.length > 0">
        <div class="pagination-selector-wrapper" :class="{active: (currentPage == 0)}"><div class="pagination-selector" @click="currentPage = 0">1</div></div>
        <div v-for="(item, i) in Math.floor((origLSVSubset.length - 1) / 20)" :key="i" :value="i+1" class="pagination-selector-wrapper" :class="{active: (currentPage == i + 1)}"><div class="pagination-selector" @click="currentPage = i + 1">{{i+2}}</div></div>
      </div>
      <div v-else>
        <div class="pagination-selector-wrapper" :class="{active: (currentPage == 0)}"><div class="pagination-selector" @click="currentPage = 0">1</div></div>
      </div>
      <div class="result-notes">Die Mittelwerte wurden aus vorliegenden Einzelwerten berechnet. Bei den Werten handelt es sich um die Relativerträge der behandelten Stufe. Alle Angaben ohne Gewähr </div>
    </div>
	</div>
</template>

<style src="@vueform/slider/themes/default.css"></style>


<style lang="scss">

body { margin: 0; }

// .maisscout {
//   background-color: #EEEEEE;
//   .app-inner {
//     display: flex; flex-direction: column;
//     height: 100vh;
//   }
// }

.controls-container {
  background-color: #EEEFEE;
  padding: 20px; padding-bottom: 0;
}

.control-buttons-wrapper {
  display: flex; flex-wrap: wrap; align-items: center;justify-content: space-between;
  margin-left: -16px; margin-right: -16px; margin-bottom: 10px;
  .control-button {
    flex: 0 0 calc(100%/2 - 32px);
    padding-left: 16px; padding-right: 16px;
    button {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      border: 1px solid #C3C3C3; border-radius: 3px;
      color: #707070;
      display: block;
      font-size: 10px; line-height: 12px;
      padding-top: 24px; padding-bottom: 4px;
      position: relative;
      width: 100%;
      &:focus {
        border-color: #E73136;
        outline: none; 
      }
      &::before {
        background-position: top center; background-repeat: no-repeat; background-size: contain;
        content: '';
        display: block;
        position: absolute; top: 6px; left: 50%;
        transform: translateX(-50%);
        width: 18px; height: 18px;
      }
      .button-title { pointer-events: none; }
    }
    &:nth-child(1) button::before { background-image: url("/img/icons/icon_search.svg"); }
    &:nth-child(2) button::before { background-image: url("/img/icons/icon_sort.svg"); }
    &:nth-child(3) button::before { background-image: url("/img/icons/icon_compare.svg"); }
  }
}
.filter-searchterm-wrapper {
  padding-left: 20px; padding-right: 20px;
  position: relative;
  input[type=text] {
    margin-top: 20px; margin-bottom: 20px;
    font-size: 18px; line-height: 23px;
    padding: 9px; padding-left: 36px;
    border: 1px solid #C3C3C3;
    border-radius: 3px;
    box-sizing: border-box;
    background: #FFFFFF;
    background-image: url("/img/icons/icon_search.svg"); background-repeat: no-repeat; background-position: top 12px left 9px;
    width: 100%;
    &:focus { outline: none; }
  }
  button {
    background-color: transparent; background-image: url("/img/icons/icon_single_result_less.svg"); background-position: top center; background-repeat: no-repeat; background-size: contain;
    content: "";
    cursor: pointer;
    display: block;
    position: absolute;
    top: 32px;
    right: 32px;
    width: 19px;
    height: 19px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3;
    border-radius: 50%;
    &:focus { outline: none; }
  }
}
.select-wrapper {
  background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
  height: 40px;
  margin-bottom: 9px;
  position: relative;
  &::after {
    border-top: 3px solid #C3C3C3; border-right: 3px solid #C3C3C3; border-bottom: 0; border-left: 0;
    content: '';
    display: inline-block;
    padding-top: 6px; padding-right: 6px;
    position: absolute; top: 15px; right: 15px;
    transform: rotate(135deg);
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3; border-radius: 3px;
    background: transparent;
    font-size: 16px;
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 9px 12px;
    &:focus { outline: none; }
  }
}
.filter-outer-wrapper {

  // margin-top: 16px;
  // margin-bottom: 16px;

  .filter-wrapper-title { margin-bottom: 16px; }
  .filter-wrapper {
    display: flex; align-items: flex-start; flex-wrap: wrap;
    // max-height: 240px;
    // overflow-y: scroll;
    .filter-group-wrapper {
      flex: 0 0 100%;
      display: flex; align-items: center; flex-wrap: wrap;
      .filter-group-title {
        color: #B5B5B5;
        flex: 0 0 100%;
        font-size: 16px; font-weight: 700; line-height: 22px;
        padding: 9px 20px;
        text-transform: uppercase;
        border-bottom: 1px solid #dedede;
        border-top: 1px solid #dedede;
      }
      &:first-child {
        .filter-group-title {
          border-top: none;
        }
      }
    }
    
    .single-filter {
      flex: 0 0 100%;
      // margin-bottom: 16px;
      &.full { flex-basis: 100% !important; margin-bottom: 16px; }
      .filter-title {
        margin-left: 21px;
      }
      &.filter-range {
        margin-bottom: 16px;
      }
      &.filter-checkbox{
        padding: 5px 20px;
        position: relative;
        .filter-title {
          padding-left: 40px;
          position: relative;
          cursor: pointer;
          // &::before {
          //   // background-color: red;
          //   content: '';
          //   pointer-events: none;
          //   position: absolute; top: 0; left: 0;
          //   width: 30px; height: 30px;
          // }
        }
        input[type=checkbox] {
          --webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          margin: 0;
          position: absolute; top: 5px; left: 20px;
          width: 30px; height: 30px;
          z-index: 1;
          background-image: url("/img/icons/check_inactive.svg");
          background-position: center; background-repeat: no-repeat; background-size: 23px;
          &:checked {
            background-image: url("/img/icons/check_active.svg");
          }
          &:focus {
            outline: none;
          }
        }
      }
      &.image-checkbox {
        input[type=checkbox]{
          &[data-img="Silomais"], &[data-img="Koernermais"], &[data-img="Biogasmais"] {
            filter: grayscale(1);
            &:checked {
              filter:grayscale(0);
            }
            &:focus {
              outline: none;
            }
          }
          &[data-img="Silomais"] {
            background-image: url("/img/icons/check_silomais.svg");
          }
          &[data-img="Koernermais"] {
            background-image: url("/img/icons/check_koernermais.svg");
          }
          &[data-img="Biogasmais"] {
            background-image: url("/img/icons/check_biogasmais.svg");
          }
        }
      }
      .filter-title {
        // margin-bottom: 8px;
        font-size: 16px; line-height: 30px;
        .range-wrapper {
          font-weight: 700;
          padding-left: 16px;
          &.active{
            color: #E73136;
          }
        }
        label {
          user-select: none;
        }
      }
      .filter-config {
        padding-left: 20px; padding-right: 20px;
        
        button {
          cursor: pointer;
          -webkit-appearance: none;
          appearance: none;
          background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
          border: 1px solid #C3C3C3; border-radius: 3px;
          color: #E73136;
          display: block;
          font-size: 16px; font-weight: bold; line-height: 20px;
          padding-top: 10px; padding-bottom: 10px;
          position: relative;
          width: 100%;
          margin-bottom: 16px;
          &:focus {
            border-color: #E73136;
            outline: none; 
          }
          &::before {
            background-position: top center; background-repeat: no-repeat; background-size: contain;
            content: '';
            display: block;
            position: absolute; top: 6px; left: 50%;
            transform: translateX(-50%);
            width: 18px; height: 18px;
          }
          .button-title { pointer-events: none; }
        }
      }
    }
  }
  .range-slider {
    width: 100%;
    margin: auto;
    text-align: center;
    position: relative;
    height: 1.5rem;
    input[type=range] {
      position: absolute;
      left: 0;
      top: .5rem;
    }

    input[type=number] {
      border: 1px solid #ddd;
      text-align: center;
      font-size: 1.6em;
      -moz-appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button { -webkit-appearance: none; }
    input[type=number]:invalid, input[type=number]:out-of-range { border: 2px solid #ff6347; }
    input[type=range] { -webkit-appearance: none; width: 100%; }
    input[type=range]:focus { outline: none; }

    input[type=range]:nth-child(2){
      background-color: transparent;
      &::-webkit-slider-runnable-track { background-color: transparent; }
      &::-moz-range-track { background-color: transparent; }
      &:focus {
        background-color: transparent;
        &::-webkit-slider-runnable-track { background-color: transparent; }
        &::-moz-range-track { background-color: transparent; }
      }
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      // animate: 0.2s;
      background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #007858 2%, #007858 98%, #E0DFDF 98%, #E0DFDF 100%);
      background-size: 10%;
      border-radius: 1px;
      box-shadow: none;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      z-index: 2;
      position: relative;
      box-shadow: 0px 0px 0px #000;
      border: 1px solid #C3C3C3;
      height: 30px;
      width: 22px;
      border-radius: 3px;
      background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
    &.active {
      input[type=range]::-webkit-slider-runnable-track{
        background-image: linear-gradient(to right, #E0DFDF 0, #E0DFDF 2%, #C11E22 2%, #C11E22 98%, #E0DFDF 98%, #E0DFDF 100%);
      }
    }
  }

}
.comparison-outer-wrapper {
  background-color: #007858;
  color: #FFFFFF;
  // margin-bottom: 16px;

  .comparison-wrapper-title { margin-bottom: 16px; }

  .comparison-wrapper {
    padding: 13px 20px;
    display: flex; align-items:center; justify-content: space-between;
    .comparison-description {
      font-size: 11px; line-height: 16px;
    }
    .comparison-button-wrapper {
      .comparison-button {
        background-color: transparent;
        color: #FFFFFF;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        font-size: 14px; line-height: 20px;
        margin-left: 12px;
        padding: 6px 24px;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        &:focus {
          outline: none;
        }
        &:hover {
          color: #007858;
          background-color: #FFFFFF;
        }
      }
    }
  }
}

.filter-container-wrapper {
  background-color: #ffffff;
  margin-left: -20px; margin-right: -20px;
  max-height: 240px;
  overflow-y: scroll;
  text-align: left;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .filter-container {
    // display: none;
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height 0.3s ease-in-out;
    &.comparison-outer-wrapper {
      position: absolute; bottom: 0; left: 0; right: 0;
      z-index: 1;
    }
    // &[data-active="true"]{
    //   display: block;
    // }
  }
}

// table tr{
//   &:first-child {
//     td { cursor: pointer; user-select: none; }
//   }
//    td img {
//     position: relative;
//     display: block;
//     margin: 0 auto;
//     width: auto;
//     height: 32px;
//     &::after {
//       content: attr(alt);
//       display: block;
//       position: absolute;
//       top: 0;
//       left: 0;
//       min-height: 100%;
//       height: auto;
//       width: 100%;
//       background-color: #ffffff;
//       z-index: 1;
//     }
//   }
// }
.results-outer-wrapper {
  background-color: #ffffff;
  margin: 0 auto;
  // max-width: 640px;
  // height: 100%;
  flex: 1;
  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .results-compared-props {
    background-color: #58645A;
    color: #ffffff;
    display: flex;
    font-size: 11px; line-height: 12px;
    position: relative;
    text-align: left;
    user-select: none;
    &::before {
      // content: '';
      display: block;
      height: 1px;
      background-color: #ffffff;
      position: absolute; bottom: 0; left: 0; right: 0;
      padding: 9px 12px;
    }
    > div {
      background-color: #58645A;
      border-bottom: 1px solid #ffffff;
      flex: 1 0 calc(12.5% - 24px);
      padding: 9px 12px;
      position: relative;
      &::before {
        background-color: #ffffff;
        content: '';
        display: block;
        position: absolute; top: 0; left: 0; bottom: 0;
        width: 1px;
      }
      &:first-child {
        flex: 1 0 calc(25% - 24px);
        &::before { content: none; }
      }
      &:first-child:last-child {
        flex-basis: calc(100% - 24px);
      }
      &[data-key] {
        cursor: pointer;
      }
    }
  }
  // .results-inner-wrapper {
    .single-result {
      cursor: pointer;
      display: flex;
      border-bottom: 1px solid #DEDEDE;
      position: relative;
      user-select: none;
      .result-title {
        background-color: #58645A;
        padding: 9px 12px;
        text-align: left;
        flex: 0 0 calc(50% - 24px);
      }
      .result-properties {
        // display: none;
        // font-size: 1rem;
        background-color: #ffffff;
        position: absolute; top: 100%; left: 0;
        padding-left: 9px;
        width: 150%; height: 0;
        z-index: 1;
        overflow: hidden;
        transition: height .3s ease-in-out;
        .result-property {
          background-color: #E8F6F5;
          border-bottom: 1px solid #DEDEDE;
          color: #1F1F1F;
          display: flex;
          text-align: left;
          .property-key {
            flex: 0 0 60%; 
            padding: 9px 12px;
          }
          .property-value {
            // flex: 0 0 35%;
            font-weight: 700;
            padding: 9px 12px;
            width: 100%;
            /*
            word-break: break-all;
            */
            img {
              position: relative;
              display: block;
              width: auto;
              height: 32px;
              &::after {
                content: attr(alt);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                min-height: 100%;
                height: auto;
                width: 100%;
                background-color: #E8F6F5;
                z-index: 1;
              }
            }
          }
        }
        .result-compare-property {
          flex: 0 0 calc(25% - 24px);
        }
      }
      &.active {
        
        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          border-color: transparent #003a2d transparent transparent;
          border-style: solid;
          border-width: 0 9px 9px 0;
          width: 0;
          height: 0;
          z-index: 10;
        }
        .result-title {
          background-color: #007858;
        }
        // .result-properties {
        //   background-color: #ffffff;
        //   display: block;
        //   position: absolute;
        //   top: 100%;
        //   left: 0;
        //   padding-left: 9px;
        //   // width: 150%;
        //   width: calc(100% - 9px);
        //   z-index: 1;
        //   overflow: hidden;
        // }
        .result-compare-property {
          background-color: #007858;
          color: #ffffff;
          &:first-child {
            color: #ffffff;
            &::after {
              background-image: url('/img/icons/icon_single_result_less.svg')
            }
          }
          &:last-child::after {
            border-color: #ffffff;
            transform: translateY(-40%) rotate(-45deg);
          }
          &:first-child:last-child .result-properties { width: 100%; }
        }
      }
      .result-compare-property {
        background-color: #FFF;
        color: #707070;
        // cursor: pointer;
        display: flex; align-items: center; flex-wrap: wrap;
        flex: 0 0 calc(25% - 24px);
        font-size: 11px; line-height: 16px;
        padding: 9px 12px;
        // pointer-events: none;
        text-align: left;
        .property-value { 
          display: flex;
          align-items: center;
          >span:first-child{
            /*float: left;*/
          flex-basis: calc(100% - 30px);
            .sold-out { 
              display: block;
              font-size: 11px; line-height: 16px;
            }
          }
          >span:nth-child(2){
            /*float: right;*/
          flex-basis: 30px;
          }
        }
        > div { flex-basis: 100%; }
        &:first-child {
          color: #E73136;
          font-size: 18px; line-height: 23px;
          flex: 0 0 calc(50% - 24px);
          img { float: right; max-width: 26px; }
          input[type=checkbox] { display: none; }
          .sold-out { 
            font-size: 11px; line-height: 16px;
          }
          // position: sticky; left: 0;
        }
        &:nth-child(2) {
          img { 
            width: 20px; height: auto; padding: 2px; 
            &#kornpic {
              &:first-child {
                margin-left: 24px;
              }
            }
            &#biogaspic {
              &:first-child {
                margin-left: 48px;
              }
            }
          }
          #silopic + #biogaspic { margin-left: 24px; }
        }
        &:first-child:last-child {
          //only "Sorte"
          flex-basis: calc(100% - 24px)

        }
        &:last-child {
              position: relative;
              &::after {
                background-color: transparent;
                border-top: 3px solid #007858;
                border-right: 3px solid #007858;
                border-bottom: 0;
                border-left: 0;
                padding-top: 8px;
                padding-right: 8px;
                top: 0;
                width: auto;
                height: auto;
                // content: '';
                content: none;
                display: block;
                position: absolute; top: 50%; right: 20px;
                transform: translateY(-66%) rotate(135deg);
                transition: all .4s ease-out;
              }
        }
        .green { display: inline-block; font-weight: 700; }
      }
    }
  // }
}

.corn-comparison-wrapper {
  background-color: #ffffff;
  margin: 0 auto;
  // max-width: 640px;
  height: 100%;
  flex: 1;
  overflow: scroll;
  width: 100%;
  position: relative;
  max-height: 300px;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .corn-comparison-table {
    .corn-comparison-thead {
      position: sticky;
      top: 0;
      z-index:1;
      .corn-comparison-tr {
        display: flex;
        .corn-comparison-th {
          flex: 0 0 160px;
          font-size: 14px;
          padding: 10px 20px;
          background-color: #fff;
          color: #E73136;
          &:first-child {
            position: sticky;
            left: 0;
            font-size: 11px;
            text-align: left;
            background-color: #58645A;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
    .corn-comparison-tbody {
      .corn-comparison-tr {
        display: flex;
        .corn-comparison-td {
          flex: 0 0 160px;
          padding: 10px 20px;
          font-size: 11px;
          font-weight: 700;
          &:first-child {
            text-align: left;
            position: sticky;
            left: 0;
            background-color: #EEEEEE;
            font-size: 11px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.grid {
  .row {
    display: flex; flex-wrap: wrap;
    margin-left: -6px; margin-right: -6px;
    .col {
      box-sizing: border-box;
      flex: 1 0 auto;
      padding-left: 6px; padding-right: 6px;
      &.col50 {
        flex: 1 0 50%;
      }
    }
  }
}
.result-pagination-wrapper {
  background-color: #ffffff;
  flex: 0 0 155px;
  .result-pagination {
    display: flex; align-items: center;
    overflow-x: scroll; overflow-y: visible;
    padding: 20px 0;
    &::-webkit-scrollbar { width: 0; height: 0; }
    .pagination-selector-wrapper {
      padding-left: 1.5rem; padding-right: 1.5rem;
      .pagination-selector {
        background-color: #F5F5F5;
        border: 3px solid #DEDEDE; border-radius: 8px;
        color: #B5B5B5;
        cursor: pointer;
        display: flex; align-items: center; justify-content: center;
        width: 2.75rem; height: 2.75rem;
      }
      &.active .pagination-selector {
        background-color: #007858;
      }
    }
  }
  .result-notes {
    font-size: 9px; line-height: 12px; 
    padding: 20px;
  }
}

* { box-sizing: border-box; }

@media screen and(min-width: 769px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 50%
      }
    }
  }
}

@media screen and(min-width: 961px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 calc(100% / 3)
      }
    }
  }
}

// @media screen and(min-width: 1366px) {
//   .filter-outer-wrapper {
//     .filter-wrapper {
//       .single-filter {
//         flex: 0 0 25%
//       }
//     }
//   }

//   .results-outer-wrapper {
//     .results-compared-props {

//       > div {
//         // flex: 1 1 2.5%;
//         flex: 1 0 6.25%;
//         padding: 8px;
//         // overflow: hidden;
//         // white-space: nowrap;
//         // text-overflow: ellipsis;
//       // transform: rotate(90deg);
//       // > span { transform: rotate(-90deg); }

//         &:first-child {
//           flex: 1 0 12.5%;
//         }
//       }
//     }
//     // .results-inner-wrapper {
//       font-size: 12px; line-height: 12px;

//       .single-result .result-compare-property {
//         // flex: 1 1 2.5%;
//         flex: 1 0 6.25%;
//         padding: 8px;
//         // overflow: hidden;
//         // white-space: nowrap;
//         // text-overflow: ellipsis;
//         &:first-child {
//           flex: 1 0 12.5%;
//         }
//         .property-value {
//           word-break: break-all;
//         }
//       }
//     // }
//   }

// }
@media screen and(min-width: 1024px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 20%;
        .filter-title {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }

  .results-outer-wrapper {
    display: table;
    border-collapse: separate;
    .results-compared-props {
      display: table-row; width: 100%;
      font-size: 10px;
      // position: sticky; top: 0;
      &::before {
        
      }
      > div {
        // flex: 1 1 2.5%;
        flex: 1 0 12.5%;
        padding: 4px;
        position: sticky; top: 0;
        z-index: 1;
        display: table-cell;
        overflow: hidden;
        > div {
          // transform: rotate(-90deg) translateX(50%); transform-origin: center;
          width: 24px; white-space: nowrap;
          padding: 4px;
          pointer-events: none;
        }
        &[data-key="qualitaetsgruppe"] > div {
          min-width: 36px;
        }
        &[data-key="sorte"] > div {
          min-width: 180px;
        }
        &[data-key="zuechtervertrieb"] {
          > div {
            min-width: 48px;
          }
        }
        
        &::before {
          
        }
        &:first-child {
          flex: 1 0 25%;
        }
      }
    }
    // .results-inner-wrapper {
      font-size: 10px; line-height: 12px;

      .single-result {
        display: table-row; width: 100%;
        .result-compare-property {
          display: table-cell;
          // flex: 1 1 2.5%;
          flex: 1 0 12.5%;
          padding: 4px;
          vertical-align: middle;
          border-bottom: 1px solid #fff;
          line-height: 16px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          &:first-child {
            flex: 1 0 25%;
          }
          .property-value {
            word-break: break-all;
          }
        }
      }
    // }
  }

}

</style>

<script>
import axios from 'axios'
import lodash from 'lodash'
import Slider from '@vueform/slider'

// import XLSX from 'xlsx'

export default {
  name: 'Maisscout',
  components: { Slider },
		data(){
			return {
        debugData: null,
        origLSV: [],
        LSVArten: [],
        LSVSorten: [],
        LSVJahre: [],
        allThreadsByArt: [],
        allThreads: [],
        LSVBlaender: [],
        LSVArtenFilter: [],
        finalLSVmittelWerte: [],
        cornGroupId: 0,
        cornGroupYear: 0,
        cornGroupValues: 0,
        cornGroupBland: '',
        searchTerm: '',
        currentPage: 0,
        sorting: {
          Key: '',
          order: 'ASC'
        },
        // testfile: null,
        // maissorten: [],
        // groupTerm: '',
        // cornData: [],
        // filteredCornData: [],
        //weiter machen
        // selfFilterFelder: {
        //   "Standort": {"Type": "groupcheck", "Value": {
        //     "kuehl": {"Type": "check", "Value": false },
        //     "normal": {"Type": "check", "Value": false },
        //     "trocken": {"Type": "check", "Value": false }
        //   }},
        //   "Nutzungsrichtung": {"Type": "groupcheck", "Value": {
        //     "Silomais": {"Type": "check", "Value": false },
        //     "Koernermais": {"Type": "check", "Value": false },
        //     "Biogasmais": {"Type": "check", "Value": false }
        //   }},
        //   "Siloreifezahl": {"Type": "range", "Value": [150, 350] },
        //   "Koernerreifezahl": {"Type": "range", "Value": [150, 350] }
        // }
        // selfFilterFelder: {}
			}
		},
    watch: {
      cornGroupId: function(){
        this.currentPage = 0
      },
      cornGroupValues: function(){
        this.currentPage = 0
      },
      cornGroupYear: function(){
        this.currentPage = 0
      },
      cornGroupBland: function(){
        this.currentPage = 0
      },
      searchTerm: function(){
        this.currentPage = 0
      }
    },
    computed: {
      LSVsortenSubset: {
        get() {
          // console.log("trigger LSVsortenSubset begin:")
          // console.log(new Date())
          let subset = this.finalLSVmittelWerte
          // console.log("anfangs: " + subset.length)
          // subset.splice(3)

          if(this.cornGroupId !== '') subset = subset.filter(singleLSV => {
             if(singleLSV.sorte.Art === parseInt(this.cornGroupId)) return singleLSV
          })
          // console.log("0. filter: " + subset.length)

          if(this.searchTerm !== '') subset = subset.filter(singleLSV => {
            if(singleLSV.sorte.Bez.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) return singleLSV
          })
          // console.log("1. filter: " + subset.length)

          if(this.cornGroupValues !== '') subset = subset.filter(singleLSV => {
            if(parseInt(this.cornGroupValues) === parseInt(singleLSV.typ)) return singleLSV
          })
          // console.log("2. filter: " + subset.length)

          if(this.cornGroupYear !== '') subset = subset.filter(singleLSV => {
            if(parseInt(this.cornGroupYear) === parseInt(singleLSV.jahr)) return singleLSV
          })
          // console.log("3. filter: " + subset.length)

          if(this.cornGroupBland !== '') subset = subset.filter(singleLSV => {
            if(singleLSV.mittelWerte.some(mw => {return mw.bundeslandID === this.cornGroupBland})) return singleLSV
          })
          // console.log("4. filter: " + subset.length)

          // console.log(subset[0])

          const selfFF = this.filterFelder

          

          //check for applied filters
          Object.values(selfFF).forEach(function(el){
            if(el.FilterErweitert !== "1"){
              switch(el.Filter){
                case 2: //select
                  //debugger // eslint-disable-line
                  subset = subset.filter(function(thread){
                    if(typeof el.selectedFilter !== 'undefined' && el.selectedFilter !== '' && thread.sorte.sorteFelder.Felder == null) return false
                    if(typeof el.selectedFilter !== 'undefined' && el.selectedFilter !== '' && thread.sorte.sorteFelder !== null){
                      const elKey = thread.sorte.sorteFelder.Felder.findIndex(function(sortenEl){
                        return (el.Key == sortenEl.Key)
                      })
                      if(thread.sorte.sorteFelder.Felder[elKey].Value !== el.selectedFilter) return false
                    }
                    return thread
                  })
                
                break

                case 1: //input min/max
                  subset = subset.filter(function(thread){
                    if(typeof el.selectedFilter[0] !== 'undefined' && typeof el.selectedFilter[1] !== 'undefined' && el.selectedFilter[0] == el.min && el.selectedFilter[1] == el.max) return thread
                    if(typeof el.selectedFilter[0] !== 'undefined' && thread.sorte.sorteFelder.Felder == null) return false
                    if(typeof el.selectedFilter[1] !== 'undefined' && thread.sorte.sorteFelder.Felder == null) return false
                    const elKey = thread.sorte.sorteFelder.Felder.findIndex(function(sortenEl){
                      return (el.Key == sortenEl.Key)
                    })
                    if(typeof el.selectedFilter[0] !== 'undefined' && thread.sorte.sorteFelder.Felder != null && parseInt(thread.sorte.sorteFelder.Felder[elKey].Value) < parseInt(el.selectedFilter[0])) return false
                    if(typeof el.selectedFilter[1] !== 'undefined' && thread.sorte.sorteFelder.Felder != null && parseInt(thread.sorte.sorteFelder.Felder[elKey].Value) > parseInt(el.selectedFilter[1])) return false
                    return thread
                  })

                break

              }
            }
          })

          // console.log(subset)
          if(this.sorting.Key !== "" && this.sorting.group !== '2'){
            // console.log('↓');
            // subset.length = 5
            const currSorting = this.sorting
            // console.log(currSorting)


            switch(currSorting.order){
              case 'ASC':
                
                // console.log('asc')

                switch(currSorting.Key) {
                  case 'bundesland':

                    subset.sort(function(a, b){
                      // console.log(currSorting.KeyID)
                      // console.log(b.mittelWerte[0].bundeslandID)
                      const aMittelWert = a.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                      const bMittelWert = b.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                      // console.log(aMittelWert)
                      // console.log(bMittelWert)
                      // console.log('__________');
                      if(!aMittelWert || typeof aMittelWert === 'undefined') { 
                        return -1
                      }
                      if(!bMittelWert || typeof bMittelWert === 'undefined') { 
                        return 1
                      }
                      return(aMittelWert.mittelWert > bMittelWert.mittelWert) ? 1 : -1
                      // return(aMittelWert) ? 1 : -1
                    })

                  break

                  case 'gesMittel':

                    subset.sort(function(a, b){
                      // console.log(currSorting.KeyID)
                      // console.log(b.mittelWerte[0].bundeslandID)
                      const aGes = (a.summe / a.anzahl)
                      const bGes = (b.summe / b.anzahl)
                      if(!aGes || typeof aGes === 'undefined') { 
                        return -1
                      }
                      if(!bGes || typeof bGes === 'undefined') { 
                        return 1
                      }
                      return(aGes > bGes) ? 1 : -1
                      // return(aMittelWert) ? 1 : -1
                    })
                  break

                  case 'sorte':

                  subset.sort(function(a, b){
                    if(typeof a.sorte.Bez === 'undefined') return -1;
                    if(typeof b.sorte.Bez === 'undefined') return 1;
                    return (a.sorte.Bez.toLowerCase() > b.sorte.Bez.toLowerCase()) ? 1 : -1
                  })

                  break

                  default:

                  // subset.sort(function(a, b){
                  //   if(typeof a.Felder[currSorting.Key] === 'undefined') return -1;
                  //   if(typeof b.Felder[currSorting.Key] === 'undefined') return 1;
                  //   return (a.Felder[currSorting.Key] > b.Felder[currSorting.Key]) ? 1 : -1
                  // })
                }
                break

              case 'DESC':
                
                // console.log('desc')

                switch(currSorting.Key) {
                  case 'bundesland':

                    subset.sort(function(a, b){
                      // console.log(currSorting.KeyID)
                      // console.log(b.mittelWerte[0].bundeslandID)
                      const aMittelWert = a.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                      const bMittelWert = b.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                      // console.log(aMittelWert)
                      // console.log(bMittelWert)
                      // console.log('__________');
                      if(!aMittelWert || typeof aMittelWert === 'undefined') { 
                        return 1
                      }
                      if(!bMittelWert || typeof bMittelWert === 'undefined') { 
                        return -1
                      }
                      return(aMittelWert.mittelWert > bMittelWert.mittelWert) ? -1 : 1
                      // return(aMittelWert) ? 1 : -1
                    })

                  break

                  case 'gesMittel':

                    subset.sort(function(a, b){
                      // console.log(currSorting.KeyID)
                      // console.log(b.mittelWerte[0].bundeslandID)
                      const aGes = (a.summe / a.anzahl)
                      const bGes = (b.summe / b.anzahl)
                      if(!aGes || typeof aGes === 'undefined') { 
                        return 1
                      }
                      if(!bGes || typeof bGes === 'undefined') { 
                        return -1
                      }
                      return(aGes > bGes) ? -1 : 1
                    })
                  break

                  case 'sorte':

                  subset.sort(function(a, b){
                    if(typeof a.sorte.Bez === 'undefined') return 1;
                    if(typeof b.sorte.Bez === 'undefined') return -1;
                    return (a.sorte.Bez.toLowerCase() > b.sorte.Bez.toLowerCase()) ? -1 : 1
                  })

                  break

                  default:

                  // subset.sort(function(a, b){
                  //   if(typeof a.Felder[currSorting.Key] === 'undefined') return 1;
                  //   if(typeof b.Felder[currSorting.Key] === 'undefined') return -1;
                  //   return (a.Felder[currSorting.Key] < b.Felder[currSorting.Key]) ? 1 : -1
                  // })
                }
              break
            }
            // console.log(subset)
            // subset.forEach(function(singleSet){
            //   console.log(singleSet.Felder[currSorting.Key])
            // })
          }


          // console.log("trigger LSVsortenSubset end:")
          // console.log(new Date())
          return subset
        }
      },
      origLSVSubset: {
        get() {
          // console.log("trigger LSVsortenSubset begin:")
          // console.log(new Date())
          let subset = this.origLSV
          // console.log("anfangs: " + subset.length)
          // subset.splice(10)

          if(this.cornGroupId !== '') subset = subset.filter(singleLSV => {
             if(singleLSV.sorte.Art === parseInt(this.cornGroupId)) return singleLSV
          })
          // console.log("0. filter: " + subset.length)

          if(this.searchTerm !== '') subset = subset.filter(singleLSV => {
            if(singleLSV.sorte.Bez.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1 || singleLSV.ort.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) return singleLSV
          })
          // console.log("1. filter: " + subset.length)

          if(this.cornGroupYear !== '') subset = subset.filter(singleLSV => {
            if(parseInt(this.cornGroupYear) === parseInt(singleLSV.jahr)) return singleLSV
          })
          // console.log("3. filter: " + subset.length)

          if(this.cornGroupBland !== '') subset = subset.filter(singleLSV => {
            if(singleLSV.ort.bundeslandID === this.cornGroupBland) return singleLSV
          })
          // console.log("4. filter: " + subset.length)

          // console.log(subset[0])

          const selfFF = this.filterFelder

          

          //check for applied filters
          Object.values(selfFF).forEach(function(el){
            if(el.FilterErweitert !== "1"){
              switch(el.Filter){
                case 2: //select
                  subset = subset.filter(function(thread){
                    // if(el.Key == 'qualitaetsgruppe') console.log(el)
                    if(typeof el.selectedFilter !== 'undefined' && el.selectedFilter !== '' && thread.sorte.sorteFelder.Felder == null) return false
                    if(typeof el.selectedFilter !== 'undefined' && el.selectedFilter !== '' && thread.sorte.sorteFelder !== null && thread.sorte.sorteFelder.Felder[el.Key] !== el.selectedFilter) return false
                    return thread
                  })
                
                break

                case 1: //input min/max
                  subset = subset.filter(function(thread){
                    if(typeof el.selectedFilter[0] !== 'undefined' && typeof el.selectedFilter[1] !== 'undefined' && el.selectedFilter[0] == el.min && el.selectedFilter[1] == el.max) return thread
                    if(typeof el.selectedFilter[0] !== 'undefined' && thread.sorte.sorteFelder.Felder == null) return false
                    if(typeof el.selectedFilter[1] !== 'undefined' && thread.sorte.sorteFelder.Felder == null) return false
                    if(typeof el.selectedFilter[0] !== 'undefined' && thread.sorte.sorteFelder.Felder != null && parseInt(thread.sorte.sorteFelder.Felder[el.Key]) < parseInt(el.selectedFilter[0])) return false
                    if(typeof el.selectedFilter[1] !== 'undefined' && thread.sorte.sorteFelder.Felder != null && parseInt(thread.sorte.sorteFelder.Felder[el.Key]) > parseInt(el.selectedFilter[1])) return false
                    return thread
                  })

                break

              }
            }
          })
          if(this.sorting.Key !== "" && this.sorting.group === '2'){
            // console.log('↓');
            // subset.length = 5
            const currSorting = this.sorting
            // console.log(currSorting)


            switch(currSorting.order){
              case 'ASC':
                
                // console.log('asc')

                switch(currSorting.Key) {
                  // case 'bundesland':

                  //   subset.sort(function(a, b){
                  //     // console.log(currSorting.KeyID)
                  //     // console.log(b.mittelWerte[0].bundeslandID)
                  //     const aMittelWert = a.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                  //     const bMittelWert = b.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                  //     // console.log(aMittelWert)
                  //     // console.log(bMittelWert)
                  //     // console.log('__________');
                  //     if(!aMittelWert || typeof aMittelWert === 'undefined') { 
                  //       return -1
                  //     }
                  //     if(!bMittelWert || typeof bMittelWert === 'undefined') { 
                  //       return 1
                  //     }
                  //     return(aMittelWert.mittelWert > bMittelWert.mittelWert) ? 1 : -1
                  //     // return(aMittelWert) ? 1 : -1
                  //   })

                  // break

                  // case 'gesMittel':

                  //   subset.sort(function(a, b){
                  //     // console.log(currSorting.KeyID)
                  //     // console.log(b.mittelWerte[0].bundeslandID)
                  //     const aGes = (a.summe / a.anzahl)
                  //     const bGes = (b.summe / b.anzahl)
                  //     if(!aGes || typeof aGes === 'undefined') { 
                  //       return -1
                  //     }
                  //     if(!bGes || typeof bGes === 'undefined') { 
                  //       return 1
                  //     }
                  //     return(aGes > bGes) ? 1 : -1
                  //     // return(aMittelWert) ? 1 : -1
                  //   })
                  // break

                  case 'sorte':

                    subset.sort(function(a, b){
                      if(typeof a.sorte.Bez === 'undefined') return -1;
                      if(typeof b.sorte.Bez === 'undefined') return 1;
                      return (a.sorte.Bez.toLowerCase() > b.sorte.Bez.toLowerCase()) ? 1 : -1
                    })

                  break

                  case 'typ':

                    subset.sort(function(a, b){
                      if(typeof a.typ === 'undefined') return -1
                      if(typeof b.typ === 'undefined') return 1
                      return (a.typ > b.typ) ? 1 : -1
                    })

                  break

                  case 'bl':

                    subset.sort(function(a, b){
                      if(typeof a.ort.bundesland.bezeichnung === 'undefined') return -1;
                      if(typeof b.ort.bundesland.bezeichnung === 'undefined') return 1;
                      return (a.ort.bundesland.bezeichnung.toLowerCase() > b.ort.bundesland.bezeichnung.toLowerCase()) ? 1 : -1
                    })

                  break

                  case 'ort':

                    subset.sort(function(a, b){
                      if(typeof a.ort.name === 'undefined') return -1;
                      if(typeof b.ort.name === 'undefined') return 1;
                      return (a.ort.name.toLowerCase() > b.ort.name.toLowerCase()) ? 1 : -1
                    })

                  break

                  case 'mw':

                    subset.sort(function(a, b){
                      if(a.ort.name !== 'Mittel Alle' && b.ort.name !== 'Mittel Alle' ) return 0
                      if(a.ort.name !== 'Mittel Alle') return -1
                      else return 1
                    })

                  break

                  case 'mittel':

                    subset.sort(function(a, b){
                      if(typeof a.mittelWert === 'undefined') return -1
                      if(typeof b.mittelWert === 'undefined') return 1
                      return (a.mittelWert > b.mittelWert) ? 1 : -1
                    })

                  break


                  case 'rel':

                    subset.sort(function(a, b){
                      if(typeof a.relWert === 'undefined') return -1
                      if(typeof b.relWert === 'undefined') return 1
                      return (a.relWert > b.relWert) ? 1 : -1
                    })

                  break

                  default:

                  // subset.sort(function(a, b){
                  //   if(typeof a.Felder[currSorting.Key] === 'undefined') return -1;
                  //   if(typeof b.Felder[currSorting.Key] === 'undefined') return 1;
                  //   return (a.Felder[currSorting.Key] > b.Felder[currSorting.Key]) ? 1 : -1
                  // })
                }
                break

              case 'DESC':
                
                // console.log('desc')

                switch(currSorting.Key) {
                  // case 'bundesland':

                  //   subset.sort(function(a, b){
                  //     // console.log(currSorting.KeyID)
                  //     // console.log(b.mittelWerte[0].bundeslandID)
                  //     const aMittelWert = a.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                  //     const bMittelWert = b.mittelWerte.find(mW => { if(mW.bundeslandID === currSorting.KeyID) return mW})
                  //     // console.log(aMittelWert)
                  //     // console.log(bMittelWert)
                  //     // console.log('__________');
                  //     if(!aMittelWert || typeof aMittelWert === 'undefined') { 
                  //       return 1
                  //     }
                  //     if(!bMittelWert || typeof bMittelWert === 'undefined') { 
                  //       return -1
                  //     }
                  //     return(aMittelWert.mittelWert > bMittelWert.mittelWert) ? -1 : 1
                  //     // return(aMittelWert) ? 1 : -1
                  //   })

                  // break

                  // case 'gesMittel':

                  //   subset.sort(function(a, b){
                  //     // console.log(currSorting.KeyID)
                  //     // console.log(b.mittelWerte[0].bundeslandID)
                  //     const aGes = (a.summe / a.anzahl)
                  //     const bGes = (b.summe / b.anzahl)
                  //     if(!aGes || typeof aGes === 'undefined') { 
                  //       return 1
                  //     }
                  //     if(!bGes || typeof bGes === 'undefined') { 
                  //       return -1
                  //     }
                  //     return(aGes > bGes) ? -1 : 1
                  //   })
                  // break

                  case 'sorte':

                    subset.sort(function(a, b){
                      if(typeof a.sorte.Bez === 'undefined') return 1;
                      if(typeof b.sorte.Bez === 'undefined') return -1;
                      return (a.sorte.Bez.toLowerCase() > b.sorte.Bez.toLowerCase()) ? -1 : 1
                    })

                  break

                  case 'typ':

                    subset.sort(function(a, b){
                      if(typeof a.typ === 'undefined') return 1
                      if(typeof b.typ === 'undefined') return -1
                      return (a.typ > b.typ) ? -1 : 1
                    })

                  break

                  case 'bl':

                    subset.sort(function(a, b){
                      if(typeof a.ort.bundesland.bezeichnung === 'undefined') return 1;
                      if(typeof b.ort.bundesland.bezeichnung === 'undefined') return -1;
                      return (a.ort.bundesland.bezeichnung.toLowerCase() > b.ort.bundesland.bezeichnung.toLowerCase()) ? -1 : 1
                    })

                  break

                  case 'ort':

                    subset.sort(function(a, b){
                      if(typeof a.ort.name === 'undefined') return 1
                      if(typeof b.ort.name === 'undefined') return -1
                      return (a.ort.name.toLowerCase() > b.ort.name.toLowerCase()) ? -1 : 1
                    })

                  break

                  case 'mw':

                    subset.sort(function(a, b){
                      if(a.ort.name !== 'Mittel Alle' && b.ort.name !== 'Mittel Alle' ) return 0
                      if(a.ort.name !== 'Mittel Alle') return 1
                      else return -1
                    })

                  break

                  case 'mittel':

                    subset.sort(function(a, b){
                      if(typeof a.mittelWert === 'undefined') return 1
                      if(typeof b.mittelWert === 'undefined') return -1
                      return (a.mittelWert > b.mittelWert) ? -1 : 1
                    })

                  break


                  case 'rel':

                    subset.sort(function(a, b){
                      if(typeof a.relWert === 'undefined') return 1
                      if(typeof b.relWert === 'undefined') return -1
                      return (a.relWert > b.relWert) ? -1 : 1
                    })

                  break

                  default:

                  // subset.sort(function(a, b){
                  //   if(typeof a.Felder[currSorting.Key] === 'undefined') return 1;
                  //   if(typeof b.Felder[currSorting.Key] === 'undefined') return -1;
                  //   return (a.Felder[currSorting.Key] < b.Felder[currSorting.Key]) ? 1 : -1
                  // })
                }
              break
            }
            // console.log(subset)
            // subset.forEach(function(singleSet){
            //   console.log(singleSet.Felder[currSorting.Key])
            // })
          }


          // console.log("trigger LSVsortenSubset end:")
          // console.log(subset.length)
          return subset
        }
      },
      paginatedLSVsortenSubset: {
        get(){
          if(this.LSVsortenSubset.length <= 20) return this.LSVsortenSubset
          else return this.LSVsortenSubset.slice((20 * this.currentPage), (20 * this.currentPage + 20))
        }
      },
      paginatedLSVorigSubset: {
        get(){
          //MARKER - hier weitermachen bzw. Problem Pagination ist dass identifier nicht unique ist für Einzelwerte da muss ich was anderes überlegen
          // console.log(this.origLSVSubset[0])
          if(this.origLSVSubset.length <= 20) return this.origLSVSubset
          else return this.origLSVSubset.slice((20 * this.currentPage), (20 * this.currentPage + 20))
        }
      },
      filterFelder: {
        get(){
          // console.log(this.$store.state.felder)
          const filterFelder = lodash.reduce(this.LSVArtenFilter, function(obj, param){
              if(param.Filter !== 0) obj[param.Key] = param
              return obj
          }, {})
          return filterFelder
        }
      }
    },
		methods: {
      getBlandVal(mW, blandId){
        // if(mW.mittelWerte.length > 0) console.log(mW)
        // console.log(blandId)
        const res = mW.mittelWerte.filter(singleMittel => {
          if(singleMittel.bundeslandID == blandId) return singleMittel.mittelWert
        })
        if(res.length > 0) return res[0].mittelWert.toFixed(1)
        else return "-"
      },
      sortSubset(event){

        //if target is already used for current sort, reverse order, else set new target
        const eventSort = event.target.dataset.sort
          document.querySelectorAll('.results-compared-props [data-key]').forEach(function(el){
            delete el.dataset.sort
          })
        if(eventSort == 'ASC') event.target.dataset.sort = 'DESC'
        else event.target.dataset.sort = 'ASC'
        const newSorting = { Key: event.target.dataset.key, order: event.target.dataset.sort, KeyID: parseInt(event.target.dataset[event.target.dataset.key]), group: this.cornGroupValues }
        this.currentPage = 0
        this.sorting = newSorting
      },
			
			switchFilter(event) {
				
        const targetId = event.target.dataset.target
        const targetEl = document.getElementById(targetId)
        const targetActive = targetEl.dataset.active
        document.querySelectorAll('.filter-container').forEach(function(filCon){
          filCon.dataset.active = false;
          filCon.style.height = "0px"
        })
        targetEl.dataset.active = (targetActive == true || targetActive == "true") ? false : true;
        targetEl.style.height = (targetActive == true || targetActive == "true") ? "0px" : targetEl.scrollHeight + "px";
        if(targetActive == true || targetActive == "true") event.target.blur()
        if(targetActive !== true && targetActive !== "true") window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth'})
			},

      
      filterResults(){
        //set min/max for changed filter
        // const filterInputs = document.querySelectorAll('[data-filter="' + event.target.dataset.filter + '"]')
        // if(filterInputs.length > 1){
        //   if(event.target == filterInputs[0]){
        //     if(parseInt(filterInputs[0].value) >= parseInt(filterInputs[1].value)){
        //       filterInputs[0].value = Math.max(parseInt(filterInputs[1].value - 1), parseInt(filterInputs[0].dataset.init))
        //     }
        //     else if(parseInt(filterInputs[0].value) < parseInt(filterInputs[0].dataset.init)){
        //       filterInputs[0].value = filterInputs[0].dataset.init
        //     }
        //     else filterInputs[0].value = parseInt(filterInputs[0].value)
        //   }
        //   else if(event.target == filterInputs[1]){
        //     if(parseInt(filterInputs[1].value) <= parseInt(filterInputs[0].value)){
        //       filterInputs[1].value = Math.min(parseInt(filterInputs[0].value + 1), parseInt(filterInputs[1].dataset.init))
        //       // console.log(filterInputs[1].value)
        //     }
        //     else if(parseInt(filterInputs[1].value) > parseInt(filterInputs[1].dataset.init)){
        //       filterInputs[1].value = filterInputs[1].dataset.init
        //     }
        //     else filterInputs[1].value = parseInt(filterInputs[1].value)
        //   }
        //   // filterInputs[0].setAttribute('max', Math.max(parseInt(filterInputs[1].value) - 1, filterInputs[0].dataset.init))

        //   // filterInputs[1].setAttribute('min', Math.min(parseInt(filterInputs[0].value) + 1, parseInt(filterInputs[1].dataset.init)))
        // }

      // Filter
      const selfFilterFelder = this.filterFelder
      // Object.values(this.filterFelder).forEach(function(filterFeld){
      //   // if(filterFeld.comparing) console.log(filterFeld)
      //   document.querySelectorAll('[data-filter="' + filterFeld.Key+ '"]').forEach(function(inputfield, idx){
      //     if(typeof inputfield.dataset.init !== 'undefined'){
      //       //input fields
      //       if(inputfield.dataset.init !== inputfield.value){
      //         selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
      //         // this.filterFelder[filterFeld.Key][idx] = inputfield.value
      //       }
      //     }
      //     else {
      //       //select
      //       if(inputfield.value !== '') {
      //         // console.log(inputfield.value)
      //         // console.log(selfFilterFelder[filterFeld.Key])
      //         // console.log(selfFilterFelder[filterFeld.Key].selectedFilter)
      //         selfFilterFelder[filterFeld.Key].selectedFilter = inputfield.value
      //       }
      //       else selfFilterFelder[filterFeld.Key].selectedFilter = ''
      //     }
      //   })
      // })

      // Object.values(this.filterFelder).forEach((filterFeld) => {
      //   // if(filterFeld.comparing) console.log(filterFeld)
      //   document.querySelectorAll('[data-filter="' + filterFeld.Key+ '"]').forEach((inputfield, idx) => {
      //     if(selfFilterFelder[filterFeld.Key].Filter == 2){
      //       //select
      //       selfFilterFelder[filterFeld.Key].selectedFilter = inputfield.value
      //       this.searchTerm = ''
      //     }
      //     else if(selfFilterFelder[filterFeld.Key].Filter == 1){
      //       //range
      //       if(inputfield.value !== '') {
      //         selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
      //         this.searchTerm = ''
      //       }
      //       else selfFilterFelder[filterFeld.Key].selectedFilter[idx] = ''
      //     }
      //   })
      // })










      
      this.selfFilterFelder = selfFilterFelder

      // return
      },
        toggleActive(event){
          if(event.target.nodeName === "IMG" || event.target.nodeName === "A") return false
          const result = event.target.closest('.single-result')
          // console.log(result)
          if(result.classList.contains('active')){
            result.children[0].children[0].style.height = "0px"
            setTimeout(function(){
              result.classList.remove('active')
            }, 300)
          }
          else {
            document.querySelectorAll('.single-result').forEach(function(singRes){
              singRes.children[0].children[0].style.height = "0px"
              setTimeout(function(){
                singRes.classList.remove('active')
              }, 300)
            })
            result.children[0].children[0].style.height = result.children[0].children[0].scrollHeight + "px";
            setTimeout(function(){
              result.classList.add('active')
            }, 300)
          }
        },
      cornComparison(){
        const cornIds = [2, 3]
        // console.log(this.cornData);
        this.filteredCornData = this.cornData.filter(corn => { if(cornIds.includes(parseInt(corn.corn_id))) return corn })
        // console.log(this.filteredCornData)
      },
      // updateArt(){
      //   axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', {
      //     "username": "0",
      //     "sessionkey": "",
      //     "artenschluessel": this.cornGroupId
      //   })
      //   .then(response => {
      //     const responseByKey = lodash.reduce(response.data.data , function(obj, param) {
      //       obj[param.Key] = param
      //       obj[param.Key].selectedFilter = obj[param.Key].FilterVal.slice()
      //       // obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
      //       return obj
      //      }, {})
      //     this.LSVArtenFilter = responseByKey
      //     // console.log(responseByKey)
      //   }).catch(e => {
      //     this.errors.push(e)
      //   })
      // },
      addMittelwert(singleLSV){
        //checken ob es für das Jahr oder für das Produkt schon was gibt, falls nicht  anlegen und danach pushen
        // console.log(this.finalLSVmittelWerte)
        if(!this.finalLSVmittelWerte.find(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )) {
          const newFinalLSVYear = {
            "identifier": "" + singleLSV.jahr + singleLSV.sorteID,
            "jahr": singleLSV.jahr,
            "sorte": singleLSV.sorte,
            "summe": 0,
            "anzahl": 0,
            "mittelWerte": [],
            "typ": singleLSV.versuch,
          }
          this.finalLSVmittelWerte.push(newFinalLSVYear);
        }
        const fittingLSVIndex = this.finalLSVmittelWerte.findIndex(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )
        if(typeof this.finalLSVmittelWerte[fittingLSVIndex] == 'undefined'){
          // console.log(fittingLSVIndex)
          // console.log(singleLSV)
          // console.log(fittingLSVIndex)
        }
        else {
          this.finalLSVmittelWerte[fittingLSVIndex].mittelWerte.push({
            "bundeslandID": singleLSV.ort.bundeslandID,
            "bundesland": singleLSV.ort.bundesland.kurzBezeichnung,
            "mittelWert": singleLSV.mittelWert,
            "relWert": singleLSV.relWert
          })
        }
      },
      addMittelwertGes(singleLSV){
        //checken ob es für das Jahr oder für das Produkt schon was gibt, falls nicht  anlegen und danach pushen
        // console.log(this.finalLSVmittelWerte)
        if(!this.finalLSVmittelWerte.find(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )) {
          const newFinalLSVYear = {
            "identifier": "" + singleLSV.jahr + singleLSV.sorteID,
            "jahr": singleLSV.jahr,
            "sorte": singleLSV.sorte,
            "summe": 0,
            "anzahl": 0,
            "mittelWerte": [],
            "typ": singleLSV.versuch,
          }
          this.finalLSVmittelWerte.push(newFinalLSVYear);
        }
        const fittingLSVIndex = this.finalLSVmittelWerte.findIndex(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )
        if(typeof this.finalLSVmittelWerte[fittingLSVIndex] == 'undefined'){
          // console.log(singleLSV)
          // console.log(fittingLSVIndex)
          // this.debugcounter++;
        }
        else {
          // this.finalLSVmittelWerte[fittingLSVIndex].mittelWerte.push({
          //   "bundeslandID": singleLSV.ort.bundeslandID,
          //   "bundesland": singleLSV.ort.bundesland.kurzBezeichnung,
          //   "mittelWert": singleLSV.mittelWert,
          //   "relWert": singleLSV.relWert
          // })
          this.finalLSVmittelWerte[fittingLSVIndex].summe+= singleLSV.relWert
          this.finalLSVmittelWerte[fittingLSVIndex].anzahl++
        }
      },

      addMittelwerte(){
        this.origLSV.forEach(singleLSV => {
          if(singleLSV.mittelWert !== 0 && singleLSV.relWert === 0){
            //checken ob es für das Jahr oder für das Produkt schon was gibt, falls nicht  anlegen und danach pushen
            // console.log(this.finalLSVmittelWerte)
            if(!this.finalLSVmittelWerte.find(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )) {
              const newFinalLSVYear = {
                "identifier": "" + singleLSV.jahr + singleLSV.sorteID,
                "jahr": singleLSV.jahr,
                "sorte": singleLSV.sorte,
                "summe": 0,
                "anzahl": 0,
                "mittelWerte": [],
                "typ": singleLSV.versuch,
              }
              this.finalLSVmittelWerte.push(newFinalLSVYear);
            }
            const fittingLSVIndex = this.finalLSVmittelWerte.findIndex(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )
            if(typeof this.finalLSVmittelWerte[fittingLSVIndex] == 'undefined'){
              // console.log(fittingLSVIndex)
              // console.log(singleLSV)
              // console.log(fittingLSVIndex)
            }
            else {
              this.finalLSVmittelWerte[fittingLSVIndex].mittelWerte.push({
                "bundeslandID": singleLSV.ort.bundeslandID,
                "bundesland": singleLSV.ort.bundesland.kurzBezeichnung,
                "mittelWert": singleLSV.mittelWert,
                "relWert": singleLSV.relWert
              })
            }
          }
          else if(singleLSV.versuch !== 2 && singleLSV.relWert !== 0){
            //checken ob es für das Jahr oder für das Produkt schon was gibt, falls nicht  anlegen und danach pushen
            // console.log(this.finalLSVmittelWerte)
            if(!this.finalLSVmittelWerte.find(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )) {
              const newFinalLSVYear = {
                "identifier": "" + singleLSV.jahr + singleLSV.sorteID,
                "jahr": singleLSV.jahr,
                "sorte": singleLSV.sorte,
                "summe": 0,
                "anzahl": 0,
                "mittelWerte": [],
                "typ": singleLSV.versuch,
              }
              this.finalLSVmittelWerte.push(newFinalLSVYear);
            }
            const fittingLSVIndex = this.finalLSVmittelWerte.findIndex(finalSingleLSV => { if(finalSingleLSV.identifier.split("-")[0] == "" + singleLSV.jahr + singleLSV.sorteID) return finalSingleLSV } )
            if(typeof this.finalLSVmittelWerte[fittingLSVIndex] == 'undefined'){
              // console.log(singleLSV)
              // console.log(fittingLSVIndex)
              // this.debugcounter++;
            }
            else {
              // this.finalLSVmittelWerte[fittingLSVIndex].mittelWerte.push({
              //   "bundeslandID": singleLSV.ort.bundeslandID,
              //   "bundesland": singleLSV.ort.bundesland.kurzBezeichnung,
              //   "mittelWert": singleLSV.mittelWert,
              //   "relWert": singleLSV.relWert
              // })
              this.finalLSVmittelWerte[fittingLSVIndex].summe+= singleLSV.relWert
              this.finalLSVmittelWerte[fittingLSVIndex].anzahl++
            }
          }
        })
      },
      updateLSVArtenFilter(){
        axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', { "username": "0", "sessionkey": "", "artenschluessel": this.cornGroupId}).then(response => {
          


          const responseByKey = lodash.reduce(response.data.data , function(obj, param) {
            obj[param.Key] = param
            if(obj[param.Key].Filter == 1) {
              obj[param.Key].selectedFilter = obj[param.Key].FilterVal.slice()
              obj[param.Key].min = obj[param.Key].FilterVal[0]
              obj[param.Key].max = obj[param.Key].FilterVal[1]
              obj[param.Key].tooltips = false
              }
            else obj[param.Key].selectedFilter = ""
            // obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
            return obj
           }, {})
          this.LSVArtenFilter = responseByKey
          axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVSIFelder.ashx', { "username": "0", "sessionkey": "" }).then(res => {
            let LSVSIFelder = res.data.list
            LSVSIFelder = LSVSIFelder.filter(sing => {
              if(sing.SIArten.includes(this.cornGroupId)) return sing
            })
            // console.log("LSVSIFelder:")
            // console.log(LSVSIFelder)
            // console.log("SIFelder:")
            // console.log(responseByKey)
          })
        })
      }
		},
		created() {
      // console.log("created:")
      // console.log(new Date())
      // getLSVArtenAxios => this.LSVArten
      // getLSVJahreAxios => this.LSVJahre
      // getLSVBlaenderAxios => this.LSVBlaender
      // felderGetAxios => this.LSVArtenFilter

      //LSVArten
      const getLSVArtenAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVArten.ashx', { "username": "0", "sessionkey": "" })
      const getLSVJahreAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVJahre.ashx', { "username": "0", "sessionkey": "" })
      const getLSVBlaenderAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVBlaender.ashx', { "username": "0", "sessionkey": "" })
      const getWerteAxios = axios.get('https://stroetmann-saat.de/lsv.json')
      // const getDebugAxios = axios.get('https://stroetmann-saat.de/lsvtest.json')
      const getLSVSortenAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVSorten.ashx', { "username": "0", "sessionkey": "" })

      axios.all([getLSVArtenAxios, getLSVJahreAxios, getLSVBlaenderAxios, getWerteAxios, getLSVSortenAxios]).then(axios.spread((...responses) => {
        this.LSVArten = responses[0].data.list
        this.cornGroupId = responses[0].data.list[0].ID
        this.LSVJahre = responses[1].data.list
        this.cornGroupYear = responses[1].data.list[responses[1].data.list.length - 1].Jahr
        this.LSVBlaender = responses[2].data.list
        // this.origLSV = responses[3].data.
        axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', { "username": "0", "sessionkey": "", "artenschluessel": responses[0].data.list[0].ID }).then(subres => {
          
          const responseByKey = lodash.reduce(subres.data.data , function(obj, param) {
            obj[param.Key] = param
            if(obj[param.Key].Filter == 1) {
              obj[param.Key].selectedFilter = obj[param.Key].FilterVal.slice()
              obj[param.Key].min = obj[param.Key].FilterVal[0]
              obj[param.Key].max = obj[param.Key].FilterVal[1]
              obj[param.Key].tooltips = false
              }
            else obj[param.Key].selectedFilter = ""
            // obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
            return obj
           }, {})
          this.LSVArtenFilter = responseByKey
          this.origLSV = responses[3].data.einzel
          this.finalLSVmittelWerte = responses[3].data.mittel
          // this.debugData = this.origLSV.filter(singleLSV => {
          //   return (singleLSV.ort.bundeslandID === 10 && singleLSV.sorte.Art === 910)
          // })
          const debugArr = [...this.origLSV];
          
          const duplicates = {};
          const seenIdentifiers = {};
          
          debugArr.forEach((item) => {
            const identifier = item.identifier;
            if (seenIdentifiers[identifier]) {
              duplicates[identifier] = duplicates[identifier] || [];
              duplicates[identifier] = item;
            } else {
              seenIdentifiers[identifier] = true;
            }
          });

          // this.debugData = responses[4].data;
          this.LSVSorten = responses[4].data.list;
          // this.debugData.forEach((debuggi) => {
          //   debuggi.sorte = this.LSVSorten.find((singleSorte) => (debuggi.sorteID === singleSorte.sorteID))
          // })
          // this.debugData = this.debugData.filter((debuggi) => {
          //   return(debuggi.sorte.Art == 950)
          //   // return(debuggi.typ == 1 && debuggi.sorte.Art == 950)
          //   // return(debuggi.sorte.Art == 910)
          //   // return(debuggi.typ == 0 && debuggi.sorte.Art == 910)
          // })
          // console.log(this.debugData);

          // this.debugData = duplicates;
          // // console.log(Object.values(this.debugData));
          // Object.values(this.debugData).forEach((dat) => {
          //   console.log('Sorte: ' + dat.sorte.Bez + ' (' + dat.sorteID + '), Ort: ' + dat.ort.name + ' (' + dat.ortID + '), Jahr: ' + dat.jahr + ', SortenArt ID: ' + dat.sorte.Art);
          // })

          // this.debugData = this.origLSV.filter(singleLSV => {
          //   return(singleLSV.sorte == null || singleLSV.ort == null)
          // })
          // this.origLSV.length = 200
          // this.finalLSVmittelWerte.length = 1000
          // console.log(this.origLSV)
          // console.log(this.finalLSVmittelWerte)
        })


      }))

      // const felderGetAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', { "username": "0", "sessionkey": "", "artenschluessel": responses[0].data.list[0].ID })

      // const getLSVAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSV.ashx', { "username": "0", "sessionkey": "" })
      //   // const getSIThreadsAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/kuposaat/getSI_threads.ashx', { "username": "0", "sessionkey": "", "artenschluessel": artenKeyArr })
      //   // const felderGetAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', { "username": "0", "sessionkey": "", "artenschluessel": response.data.list[0].ID })



      // axios.all([getLSVArtenAxios, getLSVJahreAxios, getLSVAxios]).then(axios.spread((...responses) => {
      //   // console.log("first axios.all:")
      //   // console.log(new Date())
      //   //responses[0] => getLSVArtenAxios
        
      //   this.LSVArten = responses[0].data.list
      //   this.cornGroupId = responses[0].data.list[0].ID
      //   const artenKeyArr = this.LSVArten.map(art => art.ID)

      //   const getSIThreadsAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/kuposaat/getSI_threads.ashx', { "username": "0", "sessionkey": "", "artenschluessel": artenKeyArr })
      //   const felderGetAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/sorteninfo/felder/get.ashx', { "username": "0", "sessionkey": "", "artenschluessel": responses[0].data.list[0].ID })
      





      //   //responses[1] => getLSVJahreAxios
      //   this.LSVJahre = responses[1].data.list
      //   this.cornGroupYear = responses[1].data.list[responses[1].data.list.length - 1].Jahr

      //   //responses[2] => getLSVAxios

      //   this.origLSV = responses[2].data.list

      //   const getLSVSortenAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVSorten.ashx', { "username": "0", "sessionkey": "" })
      //   const getLSVStandorteAxios = axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVStandorte.ashx', { "username": "0", "sessionkey": "" })

      //   axios.all([getSIThreadsAxios, felderGetAxios, getLSVSortenAxios, getLSVStandorteAxios]).then(axios.spread((...subresponses) => {
      //     // console.log("2. axios.all:")
      //     // console.log(new Date())
          
      //     // console.log(response.data.list)

      //     // subresponses[0] => getSIThreadsAxios
      //     if(subresponses[0].data.list.length && subresponses[0].data.list[0] && subresponses[0].data.list[0].Value && subresponses[0].data.list[0].Value.length > 0){
      //       const threadsByArt = []
      //       subresponses[0].data.list.forEach(function(artProds){
      //         artProds.Value.forEach(function(entry){
      //           entry.Felder.forEach(function(feld, idx){
      //             entry.Felder[feld.Key] = feld.Value
      //             entry.active = false
      //             // entry.mittelWerte = []
      //             delete entry.Felder[idx]
      //           })
      //         })
      //         threadsByArt.push(artProds)
      //       })
      //       this.allThreadsByArt = threadsByArt
      //       this.allThreads = []
      //       this.allThreadsByArt.forEach(artProds => {
      //         artProds.Value.forEach(artProd => {
      //           this.allThreads.push(artProd)
      //         })
      //       })
      //       // console.log(this.allThreads.find(thread => {if(thread.Name == 'APOLLON') return thread}))
      //     }


      //     // subresponses[1] => felderGetAxios
      //     const responseByKey = lodash.reduce(subresponses[1].data.data , function(obj, param) {
      //       obj[param.Key] = param
      //       if(obj[param.Key].Filter == 1) {
      //         obj[param.Key].selectedFilter = obj[param.Key].FilterVal.slice()
      //         obj[param.Key].min = obj[param.Key].FilterVal[0]
      //         obj[param.Key].max = obj[param.Key].FilterVal[1]
      //         obj[param.Key].tooltips = false
      //         }
      //       else obj[param.Key].selectedFilter = ""
      //       // obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
      //       return obj
      //      }, {})
      //     this.LSVArtenFilter = responseByKey
      //     // console.log(this.LSVArtenFilter)



      //     // subresponses[2] => getLSVSortenAxios

      //     // console.log(subresponses[2].data.list.find(thread => {if(thread.intBez == "APOLLON") return thread}))
      //     subresponses[2].data.list.forEach(singleSorte => { singleSorte.sorteFelder = this.allThreads.find(thread => { if(thread.intBez == singleSorte.Name) return thread })})
          // this.LSVSorten = subresponses[2].data.list

          // // this.origLSV.forEach(singleLSV => {
          // //   singleLSV.sorte = this.LSVSorten.find(sorte => sorte.sorteID == singleLSV.sorteID)
          // // })


      //     // subresponses[3] => getLSVStandorteAxios


            
      //     // getLSVBlaenderAxios 
      //     // axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVBlaender.ashx', {
      //     //   "username": "0",
      //     //   "sessionkey": ""
      //     // })
      //     // .then(blaenderRes => {
      //     //   // console.log("3. axios.all:")
      //     //   // console.log(new Date())
      //     //   this.LSVBlaender = blaenderRes.data.list
      //     //   // this.cornGroupBland = response.data.list[0].kurzBezeichnung
      //     //   // console.log(blaenderRes.data.list)
      //     //   // console.log(subresponses[3].data.list[0])
      //     //     subresponses[3].data.list.forEach(singleStandort => {
      //     //       singleStandort.bundesland = blaenderRes.data.list.find(bland => bland.ID == singleStandort.bundeslandID)
      //     //     })
      //     //     // console.log(subresponses[3].data.list[0])


      //     //     // console.log(this.origLSV[0])
      //     //     // this.origLSV.forEach(singleLSV => {
      //     //     //   singleLSV.identifier = "" + singleLSV.jahr + singleLSV.sorteID + "-" + singleLSV.ortID,
      //     //     //   singleLSV.sorte = this.LSVSorten.find(sorte => sorte.sorteID == singleLSV.sorteID)
      //     //     //   singleLSV.ort = subresponses[3].data.list.find(standort => standort.ID == singleLSV.ortID)
      //     //     //   if(singleLSV.ort.bundeslandID == 17) singleLSV.versuch = 2
      //     //     //   else singleLSV.versuch = singleLSV.typ



      //     //     //   // if(singleLSV.mittelWert !== 0 && singleLSV.relWert === 0){
      //     //     //   //   //zu Sorte hinzufügen
      //     //     //   //   this.addMittelwert(singleLSV)
      //     //     //   // }
      //     //     //   // else if(singleLSV.versuch !== 2 && singleLSV.relWert !== 0){
      //     //     //   //   //zu Ges. rechnen
      //     //     //   //   this.addMittelwertGes(singleLSV)
      //     //     //   // }
      //     //     // })
      //     //     // // console.log("vor addMittelwert foreach:")
      //     //     // console.log(new Date())
      //     //     // this.addMittelwerte()
      //     //     // console.log("nach addMittelwert foreach:")
      //     //     // console.log(new Date())

      //     //     //download mittelwerte json as file
      //     //     // const uriContent = "data:application/octet-stream," + encodeURIComponent(JSON.stringify(this.finalLSVmittelWerte))
      //     //     // const newWindow = window.open(uriContent, 'neuesDokument')

      //     //     // this.finalLSVmittelWerte = this.finalLSVmittelWerte.filter(singleLSV => {if(singleLSV.mittelWerte.length > 0) return singleLSV})
              
      //     //     // console.log("vor mittelwerte json get:")
      //     //     // console.log(new Date())
      //     //     // axios.get('/johnsons/mittelwerte.json')
      //     //     // .then(mittelWerteRes => {
      //     //     //   this.finalLSVmittelWerte = mittelWerteRes.data.filter(singleLSV => {if(singleLSV.mittelWerte.length > 0) return singleLSV})

      //     //     //   // console.log("nach mittelwerte json get:")
      //     //     //   // console.log(new Date())
      //     //     //   // console.log(this.finalLSVmittelWerte)
      //     //     // })
      //     //     // const finalMittelwerteJS = axios.get('/johnsons/finalMittelwerteJS.json')
      //     //     // const finalMittelwertePHP = axios.get('/johnsons/finalMittelwertePHP.json')
      //     //     // axios.all([finalMittelwerteJS, finalMittelwertePHP]).then(axios.spread((...debugres) => {
      //     //     //   console.log(debugres[0].data[0])
      //     //     //   console.log(debugres[1].data[0])
      //     //     //   this.finalLSVmittelWerte = debugres[0].data
      //     //     // }))
              

      //     // })
      //     // .catch(e => {
      //     //   console.log(e)
      //     // })


      //   }))







      // })).catch(errors => {
      //   console.log(errors)
      // })
      


      // axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVArten.ashx', {
      //   "username": "0",
      //   "sessionkey": ""
      // })
      // .then(response => {
      //   this.LSVArten = response.data.list
      // })
			// .catch(e => {
			// 	this.errors.push(e)
			// })

      // axios.post('https://portal.stroetmann.de/kupoapi_v2/lsv/getLSVArten.ashx', {
      //   "username": "0",
      //   "sessionkey": ""
      // })
      // .then(response => {
      //   this.LSVArten = response.data.list
      // })
			// .catch(e => {
			// 	this.errors.push(e)
			// })











			// axios.get('johnsons/maissorten.json')
			// .then(response => {
			// 	response.data.forEach((single) => {
			// 		single.Logo = single.Logo.replace('/./', 'https://maisscout.de/');
			// 		single.Sorte = single.Sorte.replace('-ausverkauft-', '<span class="sold-out">-ausverkauft-</span>');
			// 		single.Sorte = single.Sorte.replace('-ausverk.-', '<span class="sold-out">-ausverkauft-</span>');
			// 		single.Nutzungsrichtung = single.Nutzungsrichtung.replaceAll('/res/', 'https://maisscout.de/res/');
			// 		single.Bestellen = '<span>' + single.Bestellen + '</span>';
			// 		this.maissorten.push(single);
			// 	})
      //   this.filteredSorten = this.maissorten
      //   // const filterInputs = document.querySelectorAll('[data-filter]')



			// })
			// .catch(e => {
			// 	this.errors.push(e)
			// })
      // axios.get('https://portal.stroetmann.de/kupoapi/mais/corn_data.json')
      // .then(response => {
      //   this.cornData = response.data
      // })
      // .catch(e => {
      //   this.errors.push(e)
      // })
		}
}
</script>